import * as React from "react"
import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"

//import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <h1>Welcome to LiberSystems</h1>
    <p className="large-p">
      My internet alias is{" "}
      <a href="https://duckduckgo.com/?q=matteljay&ia=images">Matteljay</a> and
      I specialize in custom IT solutions.
    </p>
    <div className="container-links py-4">
      <div className="link-item">
        <Link className="link" to="/about">
          About
        </Link>
        <p className="list-desc">
          Some personal information explaining my values.
        </p>
      </div>
      <div className="link-item">
        <Link className="link" to="/portfolio">
          Portfolio
        </Link>
        <p className="list-desc">
          See what type of IT solutions and expertise are offered.
        </p>
      </div>
      <div className="link-item">
        <Link className="link" to="/contact">
          Contact
        </Link>
        <p className="list-desc">
          Here you can find my location and e-mail address.
        </p>
      </div>
    </div>
    <p>
      Want to dig deeper? More projects are listed here:{" "}
      <a href="https://github.com/Matteljay?tab=repositories">
        GitHub repositories
      </a>
      . And check out my{" "}
      <a href="https://stackoverflow.com/users/10365982/matteljay">
        Stack Overflow profile
      </a>
      .
    </p>
    {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    /> */}
  </>
)

export default IndexPage
